import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/assets/grafics/404.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/app/[locale]/components/common/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarFull"] */ "/app/src/app/[locale]/components/common/navbar-dark-light-rtl-ltr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuickLinkBox"] */ "/app/src/app/[locale]/components/common/QuickLinkBox.tsx");
